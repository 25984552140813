import { HttpErrorResponse } from '@angular/common/http';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ScenerActions } from './scener.actions';

import { IScener } from '@models/interfaces/scener';

export interface IScenerState extends EntityState<IScener> {
  loading: boolean;
  updated: number;
  error: HttpErrorResponse;
}

export const selectScenerUuid = (scener: IScener): string => scener.uuid;
export const adapter: EntityAdapter<IScener> = createEntityAdapter<IScener>({ selectId: selectScenerUuid });

export const initialState: IScenerState = adapter.getInitialState({
  loading: false,
  updated: null,
  error: null,
});

export type State = Readonly<typeof initialState>;

const scenerReducer = createReducer(
  initialState,
  // FETCH
  on(ScenerActions.fetchByUuid, state => ({ ...state, error: null, loading: true })),
  on(ScenerActions.fetchByUidSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false, updated: Date.now() })
  ),
  on(ScenerActions.fetchError, (state, { error }) => ({ ...state, loading: false, error, updated: Date.now() })),

  // FETCH FOR LETTER
  on(ScenerActions.fetchForLetter, state => ({ ...state, error: null, loading: true })),
  on(ScenerActions.fetchForLetterSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, error: null, loading: true })
  )
);

// eslint-disable-next-line
export function reducer(state: State | undefined, action: Action) {
  return scenerReducer(state, action);
}
