import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IApiIntro, IIntroResource, Intro } from '../../models';
import { IIntroState } from '@store/intro/intro.reducer';

export namespace IntroActions {
  // REHYDRATE
  export const rehydrate = createAction('[Intro] REHYDRATE', props<{ payload: IIntroState }>());

  // SET CURRENT

  export const setCurrent = createAction('[Intro] SET_CURRENT', props<{ intro: Intro }>());

  // PLAY

  export const play = createAction('[Intro] PLAY', props<{ id: number; origin?: string }>());

  export const playRandom = createAction('[Intro] PLAY_RANDOM');
  export const playRandomError = createAction(
    '[Intro] PLAY_RANDOM_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // STOP

  export const stop = createAction('[Intro] STOP');

  // RESET

  export const reset = createAction('[Intro] RESET');

  // UPDATE

  export const update = createAction('[Intro] UPDATE', props<{ payload: Partial<IApiIntro> }>());

  // RATE

  export const rate = createAction('[Intro] RATE', props<{ id: number; rating: number; forced?: boolean }>());
  export const rateSuccess = createAction('[Intro] RATE_SUCCESS', props<{ payload: IApiIntro }>());
  export const rateError = createAction('[Intro] RATE_ERROR', props<{ error: HttpErrorResponse }>());

  // FETCH

  export const fetch = createAction('[Intro] FETCH', props<{ ids: number[]; autoPlay?: boolean }>());
  export const fetchSuccess = createAction('[Intro] FETCH_SUCCESS', props<{ entities: IApiIntro[] }>());

  // FETCH LATEST

  export const fetchLatest = createAction('[Intro] FETCH_LATEST', props<{ size?: number }>());
  export const fetchLatestSuccess = createAction(
    '[Intro] FETCH_LATEST_SUCCESS',
    props<{ entities: IApiIntro[] }>()
  );
  export const fetchLatestError = createAction(
    '[Intro] FETCH_LATEST_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // FETCH FOR DECADE / YEAR

  export const fetchForDecade = createAction(
    '[Intro] FETCH_FOR_DECADE',
    props<{ decade: { start: number; end: number } }>()
  );
  export const fetchForYear = createAction('[Intro] FETCH_FOR_YEAR', props<{ year: number }>());

  // FETCH SCROLLTEXTS

  export const fetchScrolltexts = createAction('[Intro] FETCH_SCROLLTEXTS', props<{ id: number }>());
  export const fetchScrolltextsSuccess = createAction(
    '[Intro] FETCH_SCROLLTEXTS_SUCCESS',
    props<{ id: number; texts: string[] }>()
  );
  export const fetchScrolltextsError = createAction(
    '[Intro] FETCH_SCROLLTEXTS_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // FETCH RESOURCES

  export const fetchResources = createAction('[Intro] FETCH_RESOURCES', props<{ id: number }>());
  export const fetchResourcesSuccess = createAction(
    '[Intro] FETCH_RESOURCES_SUCCESS',
    props<{ id: number; payload: IIntroResource[] }>()
  );
  export const fetchResourcesError = createAction(
    '[Intro] FETCH_RESOURCES_ERROR',
    props<{ error: HttpErrorResponse }>()
  );

  // SHARE

  export const share = createAction('[Intro] SHARE', props<{ id: number; platform: string }>());
  export const shareSuccess = createAction('[Intro] SHARE_SUCCESS');
  export const shareError = createAction('[Intro] SHARE_ERROR', props<{ error: HttpErrorResponse }>());

  // ERRORS

  export const fetchError = createAction('[Intro] FETCH_ERROR', props<{ error: HttpErrorResponse }>());
}
