import { HttpErrorResponse } from '@angular/common/http';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { NewsEntryActions } from './news-entry.actions';
import { IApiNewsEntry } from '@models/interfaces/news-entry';

export interface INewsEntryState extends EntityState<IApiNewsEntry> {
  loading: boolean;
  years: number[];
  error: HttpErrorResponse;
  updated: number;
  hydrated: boolean;
}

const sortByDate = (a: IApiNewsEntry, b: IApiNewsEntry): number => (a.publishedAt > b.publishedAt ? -1 : 1);

export const adapter: EntityAdapter<IApiNewsEntry> = createEntityAdapter({ sortComparer: sortByDate });
export type State = Readonly<typeof initialState>;

export const initialState: INewsEntryState = adapter.getInitialState({
  years: [],
  loading: false,
  error: null,
  updated: null,
  hydrated: false,
});

const newsEntryReducer = createReducer(
  initialState,

  // REHYDRATE

  on(NewsEntryActions.rehydrate, (state, action) => ({ ...state, ...action.state, hydrated: true })),

  // RESET

  on(NewsEntryActions.reset, () => ({
    ...initialState,
    loading: false,
    updated: Date.now(),
  })),

  // FETCH YEARS

  on(NewsEntryActions.fetchYears, state => ({ ...state, loading: true })),
  on(NewsEntryActions.fetchYearsSuccess, (state, { payload }) => ({
    ...state,
    years: payload,
    loading: false,
    updated: Date.now(),
  })),
  on(NewsEntryActions.fetchYearsError, (state, { error }) => ({ ...state, loading: false, error })),

  // FETCH FOR YEAR

  on(NewsEntryActions.fetchForYear, state => ({ ...state, loading: true })),
  on(NewsEntryActions.fetchForYearSuccess, (state, { payload }) =>
    adapter.upsertMany(payload, { ...state, loading: false, updated: Date.now() })
  ),
  on(NewsEntryActions.fetchForYearError, (state, { error }) => ({ ...state, loading: false, error })),

  // FETCH LATEST

  on(NewsEntryActions.fetchLatest, state => ({ ...state, loading: true, error: null })),
  on(NewsEntryActions.fetchLatestSuccess, (state, { payload }) =>
    adapter.upsertMany(payload, {
      ...state,
      loading: false,
      updated: Date.now(),
    })
  ),
  on(NewsEntryActions.fetchLatestError, (state, { error }) => ({ ...state, loading: false, error }))
);

// eslint-disable-next-line
export function reducer(state: State | undefined, action: Action) {
  return newsEntryReducer(state, action);
}
