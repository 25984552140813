import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { UserActions } from '@store/user/user.actions';

import { BrowserInfoService, IToast, NotificationService } from '../index';
import { HttpHelper } from '@lib/http-helper';
import { DspStorage } from '@lib/dsp-storage.class';
import { DspLogger } from '@lib/dsp-logger';
import { GlobalErrorHandler } from '@services/global-error-handler';

import { IS_SERVER } from '@lib/tokens';
import { I_CRAP_WARNING_MESSAGE } from '@lib/constants';

import { environment } from '../../../environments/environment';
import * as BUILD_VERSION from '../../../public/version.json';

interface ITimings {
  iteration: number;
  started: number;
  ms: number;
}

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  static apiUrl = `${environment.apiHost}/${environment.apiVersion}/public`;

  static get isProduction(): boolean {
    return environment.production;
  }

  static isServer = false;

  public highRefreshDisplay$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private versionCheckTimeout: number;

  constructor(
    @Inject(IS_SERVER) public isServer: boolean,
    @Inject(DOCUMENT) private document: Document,
    private browserInfoService: BrowserInfoService,
    private notificationService: NotificationService,
    private store: Store<RootState>
  ) {}

  public init(): void {
    this.initVersionChecking();

    this.browserInfoService
      .init()
      .then(() => {
        this.checkIconFontLoaded();
        this.checkScreenRefreshRate();
        this.checkBrowserCompatibility();
        this.checkForFacebook();
        this.checkIsSafari();
      })
      .catch(error => {
        DspLogger.warn(error, 'EnvironmentService::init');
        GlobalErrorHandler.handleError(error);
      });
  }

  public initVersionChecking(): void {
    if (this.isServer) {
      return;
    }

    this.checkVersion().finally();
    this.bindVisibilityChangeListener();
  }

  public async checkVersion(tries = 0): Promise<void> {
    clearTimeout(this.versionCheckTimeout);
    const cachebust = Date.now();

    try {
      const response = await fetch(`${environment.host}/public/version.json?t=${cachebust}`);
      const json = await response.json();

      const { version } = BUILD_VERSION;
      const browserVersion = version;
      const serverVersion = json.version;

      if (browserVersion === serverVersion || HttpHelper.searchParamExits('reloaded')) {
        return;
      }

      HttpHelper.reloadPage('reloaded', Date.now().toString());
    } catch (e) {
      const nextTry = Math.max(tries * 2, 5);

      DspLogger.warn(e, `EnvironmentService::checkVersion. Retrying in ${nextTry} seconds (tries: ${tries})`);

      this.versionCheckTimeout = window.setTimeout(() => this.checkVersion(tries + 1), nextTry * 1000);
    }
  }

  private bindVisibilityChangeListener(): void {
    if (!('visibilityState' in this.document)) {
      return;
    }

    this.document.addEventListener('visibilitychange', () => {
      if (this.document.visibilityState !== 'visible') {
        return;
      }

      this.checkVersion().finally();
    });
  }

  private checkScreenRefreshRate(): void {
    if (this.isServer) {
      return;
    }

    setTimeout(() => {
      const timings = { iteration: -1, started: Date.now(), ms: 0 };
      requestAnimationFrame(() => this.frameIterationFn(timings));
    }, 1000);
  }

  private frameIterationFn(timings: ITimings): void {
    try {
      const now = Date.now();
      timings.iteration = timings.iteration + 1;
      timings.ms += now - timings.started;
      timings.started = now;
    } catch (e) {
      DspLogger.warn(e, 'EnvironmentService::frameIterationFn');
      this.highRefreshDisplay$.next(false);
      return;
    }
    if (timings.iteration < 60) {
      requestAnimationFrame(() => this.frameIterationFn(timings));
    } else {
      try {
        const avg = 1000 / (timings.ms / timings.iteration);
        this.highRefreshDisplay$.next(avg > 70);
      } catch (e) {
        DspLogger.warn(e, 'EnvironmentService::frameIterationFn (2)');
      }

      this.highRefreshDisplay$.next(false);
    }
  }

  private checkBrowserCompatibility(): void {
    if (this.isServer || this.browserInfoService.satisfiesMinVersion) {
      return;
    }

    this.store.dispatch(
      UserActions.setActivity({ kind: `Browser warning shown (${this.browserInfoService.fullName})` })
    );

    this.notificationService.showToast({
      message: "It seems like you're using an outdated browser - newer Intros will not run on it.",
      icon: 'report_problem_outline',
      bgColor: 'var(--dsp-color-red)',
    });
  }

  private checkIsSafari(): void {
    if (
      this.isServer ||
      !this.browserInfoService.isSafari ||
      DspStorage.hasItem('SAFARI_WARNING', sessionStorage)
    ) {
      return;
    }

    const toast: IToast = {
      message: I_CRAP_WARNING_MESSAGE,
      bgColor: 'var(--dsp-color-red)',
      icon: 'info_outline',
    };

    DspStorage.setItem('SAFARI_WARNING', '1', sessionStorage);
    this.store.dispatch(UserActions.showToast({ toast }));
  }

  private checkForFacebook(): void {
    if (!this.browserInfoService.isFacebook) {
      return;
    }

    this.store.dispatch(UserActions.setActivity({ kind: 'Facebook warning shown' }));

    this.notificationService.showToast({
      message:
        "It seems like you're using Facebook's internal browser. Please use a regular browser in case you run into issues.",
      icon: 'report_problem_outline',
      bgColor: 'var(--dsp-color-red)',
    });
  }

  checkIconFontLoaded(tries = 0) {
    if (this.isServer) {
      return;
    }

    const fontLoaded = document.fonts.check('1rem Material Icons Outlined');

    if (fontLoaded || tries > 5) {
      document.body.setAttribute('data-icons-loaded', '1');
      return;
    }

    setTimeout(() => {
      this.checkIconFontLoaded(tries + 1);
    }, 500);
  }
}
