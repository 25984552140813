import { Location } from '@angular/common';
import {
  computed,
  effect,
  inject,
  Injectable,
  signal,
  Signal,
  untracked,
  WritableSignal,
} from '@angular/core';

import { firstValueFrom, retry } from 'rxjs';

import { RootState } from '@store/index';
import { Store } from '@ngrx/store';
import { UserSelectors } from '@store/user/user.selectors';
import { UserActions } from '@store/user/user.actions';

import { ApiService } from '@services/api/api.service';
import { DspStorage } from '@lib/dsp-storage.class';

import { IApiUser, User } from '@models/index';
import { LOCALSTORAGE_ID_KEY } from '@lib/constants';
import { IS_SERVER } from '@lib/tokens';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  public $user: Signal<User>;
  private isServer = inject(IS_SERVER);
  private location = inject(Location);
  private api = inject(ApiService);
  private store = inject<Store<RootState>>(Store);

  static $user: WritableSignal<User> = signal(null);
  static $userId = computed(() => UserService.$user()?.id ?? 0);
  static $isAdmin = computed(() => {
    if (!environment.production) {
      return true;
    }

    return UserService.$user()?.isAdmin ?? false;
  });

  constructor() {
    this.$user = this.store.selectSignal(UserSelectors.selectUser, { equal: (a, b) => a?.id === b?.id });

    effect(() => {
      const user = this.$user();

      if (!user) {
        return;
      }

      this.storeId(user.id);
      untracked(() => UserService.$user.set(user));
    });
  }

  get userId(): number | null {
    if (this.isServer) {
      return null;
    }

    return this.$user()?.id || this.storedUserId || null;
  }

  get storedUserId(): number {
    return this.getIdFromLocalstorage();
  }

  public update(data: Partial<IApiUser>): void {
    this.store.dispatch(UserActions.update({ data }));
  }

  public async getCurrentUser(): Promise<void> {
    if (this.isServer) {
      return null;
    }

    return firstValueFrom(
      this.api
        .get<IApiUser>('users/current', null, { 'X-Req-Origin': this.location.path() || '/' })
        .pipe(retry({ count: 3, delay: 500 }))
    )
      .then(response => {
        if (response.payload) {
          this.store.dispatch(UserActions.getCurrentSuccess({ user: response.payload }));
        } else if (response.error) {
          throw new Error(response.error?.message, { cause: response.error });
        }
      })
      .catch(e => {
        this.store.dispatch(UserActions.getCurrentError({ error: e.error }));
      });
  }

  public storeId(id: number = this.userId): void {
    DspStorage.setItem(LOCALSTORAGE_ID_KEY, id.toString());
  }

  private getIdFromLocalstorage(): number {
    if (this.isServer) {
      return null;
    }

    try {
      const id: string = DspStorage.getItem(LOCALSTORAGE_ID_KEY);
      return id ? parseInt(id, 10) : null;
    } catch (e) {
      return null;
    }
  }
}
