import { Injectable, signal, WritableSignal } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { UserActions } from '@store/user';

export interface INotification {
  headline?: string;
  message?: string;
  type?: string;
  buttonText?: string;
  callback?: () => unknown;
}

export interface IToast {
  id?: string;
  message: string;
  icon?: string;
  bgColor?: string;
  buttonText?: string;
  callback?: () => unknown;
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  public $dialog: WritableSignal<INotification> = signal(null);
  public $toasts: WritableSignal<IToast[]> = signal([]);

  constructor(private actions$: Actions) {
    this.actions$.pipe(ofType(UserActions.showToast)).subscribe(action => this.showToast(action.toast));
  }

  public showToast(toast: IToast): void {
    toast.id = `toast-${Date.now()}-${Math.random()}`;
    this.$toasts.update(toasts => [...toasts, toast]);
  }

  public clearDialog(runCallback = true): void {
    if (this.$dialog()?.callback && runCallback) {
      this.$dialog().callback();
    }

    this.$dialog.set(null);
  }

  public removeToast(id: string): void {
    this.$toasts.update(toasts => [...toasts.filter(t => t.id !== id)]);
  }
}
