import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

import { TJsonLd, TOpenGraphConfig, TTwitterCardConfig } from '@models/interfaces/seo';
import { ICrackergroupSkeleton, IPageMetas } from '@interfaces';

import { DEFAULT_SEO_CONFIG } from '@core/config/defaults/seo';
import { environment } from '../../../environments/environment';

import { uniq } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  static buildGroupsJsonLd(skeletons: ICrackergroupSkeleton[]): TJsonLd {
    const letters = uniq(skeletons.map(({ letter }) => letter.toLowerCase()));

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: letters.map((letter, i) => {
        return {
          '@type': 'ListItem',
          position: i,
          name: `Groups index '${letter.toUpperCase()}'`,
          item: `https://www.docsnyderspage.com/groups/${letter}`,
        };
      }),
    };
  }

  constructor(
    private meta: Meta,
    private router: Router
  ) {}

  public updateMetas(action: { metas: IPageMetas } = null): void {
    const metas = action?.metas || ({} as IPageMetas);
    const ogConfig: TOpenGraphConfig = {
      type: 'website',
      url: environment.host + this.router.url,
      title: metas.title || DEFAULT_SEO_CONFIG.OG.title,
      description: metas.description || DEFAULT_SEO_CONFIG.OG.description,
    };

    if (metas.image) {
      ogConfig.image = {
        type: 'image/png',
        width: 768,
        height: 530,
        secure_url: metas.image,
      };
    }

    this.buildOpenGraphMetas(ogConfig);

    const twitterConfig: Partial<TTwitterCardConfig> = {
      title: metas.title || DEFAULT_SEO_CONFIG.TWITTER.title,
      image: metas.image || DEFAULT_SEO_CONFIG.TWITTER.image,
    };

    this.buildTwitterMetas(twitterConfig);
  }

  public buildOpenGraphMetas(config: TOpenGraphConfig = DEFAULT_SEO_CONFIG.OG, prefix: string = ''): void {
    Object.entries(config).forEach(([k, v]) => {
      if (typeof v === 'object') {
        this.buildOpenGraphMetas(v as TOpenGraphConfig, k);
      } else {
        const property = ['og', prefix, k].filter(item => !!item).join(':');

        if (v.length > 180) {
          v = v.slice(0, 180) + '...';
        }

        this.meta.removeTag(`property="${property}"`);
        this.meta.addTag({ property, content: v });
      }
    });
  }

  public buildTwitterMetas(config: TTwitterCardConfig = {}): void {
    Object.entries({ ...DEFAULT_SEO_CONFIG.TWITTER, ...config }).forEach(([k, content]) => {
      const key = `twitter:${k}`;

      this.meta.removeTag(`name="${key}"`);
      this.meta.addTag({ name: key, content });
    });
  }
}
