import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY, Observable, of, withLatestFrom } from 'rxjs';
import { debounceTime, map, mergeMap, shareReplay, switchMap } from 'rxjs/operators';

import { Action, Store } from '@ngrx/store';

import { RootState } from '@store/index';
import { UserActions } from '@store/user';
import { IntroActions } from './intro.actions';
import { IntroSelectors } from '@store/intro/intro.selectors';

import { IApiIntro, IApiUser, IIntroResource } from '@interfaces';

import { ApiService } from '../../services';
import { DspStorage } from '@lib/dsp-storage.class';
import { WINDOW_ID } from '@lib/tokens';

@Injectable()
export class IntroEffects {
  private store: Store<RootState> = inject(Store);
  private windowId = inject(WINDOW_ID, { optional: true });

  constructor(
    private actions$: Actions,
    private api: ApiService
  ) {}

  // PLAY

  play$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.play),
      debounceTime(250),
      withLatestFrom(this.store.select(IntroSelectors.selectRawEntities)),
      mergeMap(([{ id, origin }, entities]) => {
        if (entities[id]) {
          return this.api
            .post<{ user: IApiUser; aid: number }>(`intros/${id}/play`, { origin, wid: this.windowId })
            .pipe(
              map(({ payload, error }) => {
                if (payload?.user) {
                  DspStorage.setItem('DSPAID', { id, aid: payload.aid });
                  return UserActions.updateSuccess({ user: payload.user });
                }

                return UserActions.updateError({ error });
              })
            );
        }

        return of(IntroActions.fetch({ ids: [id], autoPlay: true }));
      })
    )
  );

  // PLAY RANDOM

  playRandom$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.playRandom),
      mergeMap(() =>
        this.api.get<IApiIntro>('intros/random').pipe(
          switchMap(({ error, payload }) => {
            if (payload) {
              return [
                IntroActions.fetchSuccess({ entities: [payload] }),
                IntroActions.play({ id: payload.id }),
              ];
            }
            return of(IntroActions.playRandomError({ error }));
          })
        )
      )
    )
  );

  // FETCH LATEST

  fetchLatest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.fetchLatest),
      mergeMap(action => {
        const url = action.size ? `intros/latest/${action.size}` : 'intros/latest';
        return this.api.get<IApiIntro[]>(url).pipe(
          map(({ payload, error }) => {
            if (payload) {
              return IntroActions.fetchLatestSuccess({ entities: payload });
            }
            return IntroActions.fetchLatestError({ error });
          })
        );
      })
    )
  );

  // FETCH

  fetch: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.fetch),
      mergeMap(({ ids, autoPlay }) => {
        if (!ids?.length) {
          return EMPTY;
        }

        return this.api.get<IApiIntro[]>('intros', { ids: ids.join(',') }).pipe(
          switchMap(({ payload, error }) => {
            const actions: Action[] = [];

            if (payload) {
              actions.push(IntroActions.fetchSuccess({ entities: payload }));

              if (autoPlay) {
                actions.push(IntroActions.play({ id: ids[0] }));
              }
            } else {
              actions.push(IntroActions.fetchError({ error }));
            }

            return actions;

            // if (!payload) {
            //   return IntroActions.fetchError({ error });
            // }
            //
            // const actions = [IntroActions.fetchSuccess({ entities: payload })];
            //
            // return actions;
          })
        );
      })
    )
  );

  // FETCH FOR YEAR / DECADE

  fetchForYear: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.fetchForYear, IntroActions.fetchForDecade),
      mergeMap(action => {
        const isForYear = action.type === IntroActions.fetchForYear.type;

        const path = isForYear ? `year/${action.year}` : `decade/${action.decade.start}`;

        return this.api.get<IApiIntro[] | { entities: IApiIntro[] }>(`timeline/intros/${path}`).pipe(
          map(({ payload, error }) => {
            if (error) {
              return IntroActions.fetchError({ error });
            }

            const entities = isForYear
              ? (payload as IApiIntro[])
              : (payload as { entities: IApiIntro[] }).entities;

            return IntroActions.fetchSuccess({ entities: entities ?? [] });
          })
        );
      })
    )
  );

  // FETCH SCROLLTEXTS

  fetchScrolltexts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.fetchScrolltexts),
      mergeMap(({ id }) =>
        this.api.get<string[]>(`intros/${id}/scrolltexts`).pipe(
          map(({ payload, error }) => {
            if (payload) {
              return IntroActions.fetchScrolltextsSuccess({ id, texts: payload });
            }
            return IntroActions.fetchScrolltextsError({ error });
          }),
          shareReplay()
        )
      )
    )
  );

  // FETCH RESOURCES

  fetchResources$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.fetchResources),
      mergeMap(({ id }) =>
        this.api.get<IIntroResource[]>(`intros/${id}/resources`).pipe(
          map(({ payload, error }) => {
            if (payload) {
              return IntroActions.fetchResourcesSuccess({ id, payload });
            }
            return IntroActions.fetchResourcesError({ error });
          })
        )
      )
    )
  );

  // RATE

  rate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.rate),
      mergeMap(({ id, rating, forced }) =>
        this.api.post<{ intro: IApiIntro; f: boolean }>('intros/rate', { id, rating, f: forced }).pipe(
          switchMap(({ payload, error }) => {
            if (payload) {
              return [
                IntroActions.rateSuccess({ payload: payload.intro }),
                UserActions.updateMetas({ metas: { fw: payload.f } }),
              ];
            }

            return of(IntroActions.rateError({ error }));
          })
        )
      )
    )
  );

  // SHARE

  share$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.share),
      switchMap(({ id, platform }) =>
        this.api.post('intros/share', { id, platform }).pipe(
          map(response => {
            if (response.error) {
              return IntroActions.shareError({ error: response.error });
            }
            return IntroActions.shareSuccess();
          })
        )
      )
    )
  );
}
