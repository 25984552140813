import { createSelector } from '@ngrx/store';
import { Crackergroup, IApiCrackergroup, ICrackergroupSkeleton } from '../../models';
import { initialState, RootState } from '../index';

export namespace CrackergroupSelectors {
  export const selectState = (state: RootState = initialState) => state.crackergroups;

  export const selectSkeletons = createSelector(selectState, state =>
    state.skeletons.sort((a, b) => (a.name > b.name ? 1 : -1))
  );

  export const selectSkeletonsDictionary = createSelector(selectSkeletons, items =>
    items.reduce((p, c) => ({ ...p, [c.pathname]: c }), {} as Record<string, ICrackergroupSkeleton>)
  );

  export const selectById = (id: number) =>
    createSelector(selectState, state => (state.entities[id] ? new Crackergroup(state.entities[id]) : null));

  export const selectLetters = createSelector(selectSkeletons, skeletons =>
    Array.from(skeletons.reduce((p, c) => p.add(c.letter), new Set<string>()))
  );

  export const selectCurrent = createSelector(selectState, state => {
    if (!state.current) {
      return null;
    }

    const entity: IApiCrackergroup = state.entities[state.current];
    return entity ? new Crackergroup(entity) : null;
  });

  export const selectIdByPath = (groupPath: string = null) =>
    createSelector(selectSkeletons, skeletons => {
      if (!groupPath) {
        return null;
      }

      if (groupPath.includes('#')) {
        groupPath = groupPath.split('#')[0];
      }

      return skeletons.find(skeleton => skeleton.path.endsWith(groupPath))?.id;
    });

  export const selectRandomGroup = createSelector(selectSkeletons, groups => {
    if (!groups?.length) {
      return null;
    }

    return groups[Math.floor(Math.random() * groups.length)];
  });

  export const selectEtagFor = (groupId: number) =>
    createSelector(selectById(groupId), group => group?.etag ?? null);
}
