import { BaseModel } from '@models/classes/base.class';

export const compareEntities = <T extends BaseModel>(a: T | T[], b: T | T[]): boolean => {
  if (!a || !b) {
    return a === b;
  }

  a = Array.isArray(a) ? a : [a];
  b = Array.isArray(b) ? b : [b];

  if (a.length !== b.length) {
    return false;
  }

  let result = true;
  let i = 0;

  while (result && i < a.length) {
    if (a[i].cacheKey !== b[i].cacheKey) {
      result = false;
    }
    i++;
  }

  return result;
};

export const compareByHash = <T extends object>(a: T, b: T): boolean => {
  return JSON.stringify(a || {}) === JSON.stringify(b || {});
};

export const buildDecade = (year: string | number): { start: number; end: number } | null => {
  const startYear = typeof year === 'number' ? year : parseInt(year, 10);

  if (isNaN(startYear)) {
    return null;
  }

  const start = Math.floor(startYear / 10) * 10;
  const end = Math.min(new Date().getFullYear(), start + 9);

  if (start > end) {
    return null;
  }

  return { start, end };
};
