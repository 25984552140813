import { DEFAULT_INTRO_METAS, HOST, MAX_NAME_LENGTH } from '@lib/constants';
import {
  IApiIntro,
  IApiV4SoundConfig,
  ICrackergroupSkeleton,
  IIntroFeatures,
  IIntroMetas,
  ILegacySoundConfig,
} from '@interfaces';
import { BaseModel } from './base.class';
import { AudioHelper } from '@lib/audio-helper.class';
import { buildDecade } from '@lib/helpers';

export class Intro extends BaseModel implements IApiIntro {
  public path: string = null;
  public title: string = null;
  public label: string = null;
  public fragment: string = null;
  public imageUrl: string = null;
  public csdbId: number = null;
  public groups: ICrackergroupSkeleton[] = [];
  public features: IIntroFeatures = null;
  public audioConfig: IApiV4SoundConfig = null;
  public metas: IIntroMetas = { ...DEFAULT_INTRO_METAS };

  private audioHelper: AudioHelper;

  constructor(props: Partial<IApiIntro> = {}) {
    super();
    this.setProps(props);
  }

  /*
   * General
   */

  get primaryGroup(): ICrackergroupSkeleton {
    return this.groups.find(g => g.primary);
  }

  /*
   * Paths
   */

  get url(): string {
    return HOST + `${this.path}#${this.fragment}`;
  }

  get timelinePath(): string {
    if (!this.releasedAt.year) {
      return null;
    }

    const decade = buildDecade(this.releasedAt.year);
    return `/timeline/${decade.start}-${decade.end}/${this.releasedAt.year}`;
  }

  /*
   * Strings
   */

  public override toString(shorten = false, preferHandle = false): string {
    return this.fullName(shorten, preferHandle);
  }

  public fullName(shorten = false, preferHandle = false): string {
    if (!shorten) {
      return `${this.groupsString} ${this.title}`;
    }

    if (preferHandle) {
      return `${this.groupsStringHandles} ${this.title}`;
    }

    return `${this.groupsStringShort} ${this.title}`;
  }

  get sortedGroups(): ICrackergroupSkeleton[] {
    return this.groups.sort(a => (a.primary ? -1 : 1));
  }

  get shortName(): string {
    if (this.label) {
      return this.label;
    }

    const introIndex = this.title.split('#').at(-1);
    const groups = this.groups.map(group => group.handle || group.name).join('+');
    return [groups, introIndex].join(' #');
  }

  get groupsString(): string {
    if (!this.cache.groupsString) {
      const value = this.sortedGroups.map(g => g.name).join(' + ');
      this.cache.groupsString = value || ' ';
    }

    return this.cache.groupsString as string;
  }

  get groupsStringShort(): string {
    if (this.cache.groupsStringShort) {
      return this.cache.groupsStringShort;
    }

    if (this.groups.length < 2 && this.primaryGroup?.name.length < MAX_NAME_LENGTH) {
      this.cache.groupsStringShort = this.groupsString;
    } else {
      this.cache.groupsStringShort = this.sortedGroups
        .map(g => (g.name.length >= MAX_NAME_LENGTH ? g.handle || g.name : g.name))
        .join(' + ');
    }

    return this.cache.groupsStringShort ?? '';
  }

  get groupsStringHandles(): string {
    if (this.cache.groupsStringHandles) {
      return this.cache.groupsStringHandles as string;
    }

    this.cache.groupsStringHandles = this.sortedGroups
      .map(g => (g.handle ? `[${g.handle}]` : g.name))
      .join(' + ');
    return this.cache.groupsStringHandles;
  }

  get labelOrTitle(): string {
    return this.label || this.title;
  }

  get alt(): string {
    return `${this.toString()} Preview Image`;
  }

  /*
   * Stats
   */

  get publishedAt(): Date {
    return this.metas.publishedAt as Date;
  }

  get rating(): number {
    return this.metas.rating;
  }

  /*
   * Audio
   */

  get hasSound(): boolean {
    return this.features.sid || this.features.mp3;
  }

  get soundEnabled(): boolean {
    return this.audioConfig?.mp3?.play !== false || this.audioConfig?.sid?.play !== false;
  }

  get hasSid(): boolean {
    return this.features.sid;
  }

  get hasMp3(): boolean {
    return this.features.mp3;
  }

  get usesAudioManager(): boolean {
    return this.audioHelper.usesAudioManager;
  }

  get soundConfig(): IApiV4SoundConfig | ILegacySoundConfig {
    return this.audioHelper.soundConfig;
  }

  public async initAudio(): Promise<boolean | IApiV4SoundConfig> {
    if (this.audioHelper) {
      return Promise.resolve(true);
    }

    this.audioHelper = new AudioHelper();

    return await this.audioHelper.init(this).catch(() => false);
  }

  /*
   * Metas
   */

  get apiVersion(): number {
    return this.metas?.apiVersion || -1;
  }

  get releasedAt(): { day?: string; month?: string; year?: number } {
    if (typeof this.cache['releasedAt'] !== 'undefined') {
      return this.cache['releasedAt'];
    }

    if (!this.metas?.releaseDate?.year) {
      this.cache['releasedAt'] = null;
      return null;
    }

    const releasedAt: { day?: string; month?: string; year?: number } = {
      day: null,
      month: null,
      year: null,
    };

    if (this.metas.releaseDate?.day) {
      releasedAt.day = this.metas.releaseDate.day.toString().padStart(2, '0');
    }

    if (this.metas.releaseDate?.month) {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      releasedAt.month = months[this.metas.releaseDate.month - 1];
    }

    if (this.metas.releaseDate?.year) {
      releasedAt.year = this.metas.releaseDate.year;
    }

    this.cache['releasedAt'] = releasedAt;
    return releasedAt;
  }

  get releaseDate(): Date {
    if (typeof this.cache['releaseDate'] !== 'undefined') {
      return this.cache['releaseDate'] as Date;
    }

    if (!this.releasedAt?.year) {
      this.cache['releaseDate'] = null;
      return null;
    }

    const [day, month] = (['day', 'month'] as const).map(item =>
      (this.releasedAt?.[item] ?? 1).toString().padStart(2, '0')
    );

    this.cache['releaseDate'] = new Date(`${this.releasedAt.year}-${month}-${day}`);
    return this.cache['releaseDate'];
  }

  get decade(): string | null {
    if (typeof this.cache['decade'] !== 'undefined') {
      return this.cache['decade'];
    }

    if (!this.releasedAt?.year) {
      this.cache['decade'] = null;
      return null;
    }

    this.cache['decade'] = buildDecade(this.releasedAt.year);

    return this.cache['decade'];
  }

  protected override setProps(args: Partial<IApiIntro>): void {
    super.setProps(args, ['metas']);

    if (args.metas) {
      const metas: Partial<IIntroMetas> = args.metas || {};

      if (metas.publishedAt) {
        metas.publishedAt = new Date(metas.publishedAt);
      }

      this.metas = { ...DEFAULT_INTRO_METAS, ...this.metas, ...metas };
    }

    this.groups.forEach(group => {
      if (group.path) {
        return;
      }
      group.path = `/groups/${group.letter}/${group.pathname}`;
    });
  }
}
