import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IApiUserComment, Intro } from '../../models';
import { ICommentState } from '@store/comment/comment.reducer';

export namespace CommentActions {
  // REHYDRATE

  export const rehydrate = createAction('[Comment] REHYDRATE', props<{ payload: ICommentState }>());

  // FETCH

  export const fetch = createAction('[Comment] FETCH', props<{ id?: number; introId?: number }>());
  export const fetchSuccess = createAction(
    '[Comment] FETCH_SUCCESS',
    props<{ payload: IApiUserComment[] }>()
  );
  export const fetchError = createAction('[Comment] FETCH_ERROR', props<{ error: HttpErrorResponse }>());

  // CREATE

  export const create = createAction('[Comment] CREATE', props<{ payload: IApiUserComment }>());
  export const createSuccess = createAction(
    '[Comment] CREATE_SUCCESS',
    props<{ payload: IApiUserComment }>()
  );
  export const createError = createAction('[Comment] CREATE_ERROR', props<{ error: HttpErrorResponse }>());

  // FORM

  export const initForm = createAction('[Comment] INIT_FORM', props<{ intro: Intro }>());
  export const showForm = createAction('[Comment] SHOW_FORM', props<{ introId: number }>());
}
