import { inject, Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';

import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { UserActions } from '@store/user/user.actions';

import { UserService } from '@services/user/user.service';

import { IUserActivity } from '@models/interfaces/user-activity';
import { IS_SERVER, WINDOW_ID } from '@lib/tokens';
import { REQUEST } from '@lib/tokens';

@Injectable({ providedIn: 'root' })
export class UserTrackingService {
  private buffer: IUserActivity[] = [];
  private navigation: string[] = [];

  private injector = inject(Injector);
  private isServer = inject(IS_SERVER);
  private request = inject(REQUEST, { optional: true });
  private windowId = inject(WINDOW_ID, { optional: true });
  private router = inject(Router);
  private store: Store<RootState> = inject(Store);

  constructor() {
    this.subscribeToRouter();
  }

  public async init(): Promise<void> {
    if (this.isServer) {
      return;
    }

    await firstValueFrom(
      toObservable(UserService.$user, { injector: this.injector }).pipe(filter(user => !!user))
    );

    this.processBuffer();
  }

  public setActivity(kind: string, extras: string = null): void {
    if (this.isServer) {
      return;
    }

    const payload: IUserActivity = this.buildPayload(kind, extras);
    this.dispatch(payload);
  }

  private subscribeToRouter(): void {
    this.router.events.pipe(filter(ev => ev instanceof NavigationEnd && !this.isServer)).subscribe(ev => {
      this.addNavigation((ev as NavigationEnd).url);

      const extras = this.getExtras();
      const stat = this.buildPayload('navigation', extras);

      this.dispatch(stat);
    });
  }

  private buildPayload(kind: string = 'navigation', extras: string = null): IUserActivity {
    let navigation: { from?: string; to?: string } = null;

    if (this.navigation?.length > 1) {
      const [from, to] = this.navigation;
      navigation = { from, to };
    }

    const url = this.request ? this.request.url : typeof location === 'undefined' ? null : location.pathname;

    return {
      userId: UserService.$userId(),
      kind,
      value: { url, extras, wid: this.windowId ?? '', navigation },
    };
  }

  private getExtras(): string {
    const navigation = this.router.getCurrentNavigation();
    const extras = navigation.extras?.state?.tracking;

    if (!extras) {
      return null;
    }

    try {
      return Object.entries(extras)
        .map(([k, v]) => `${k}:${v}`)
        .join(',');
    } catch (e) {
      return null;
    }
  }

  private addNavigation(url: string = null): void {
    this.navigation.push(url);

    if (this.navigation.length > 2) {
      this.navigation.shift();
    }
  }

  private dispatch(activity: IUserActivity = null): void {
    if (!activity) {
      return;
    }

    const userId = UserService.$userId() || activity.userId;

    if (!userId) {
      this.buffer.push(activity);
      return;
    }

    const hashed = btoa(JSON.stringify(activity));
    this.store.dispatch(UserActions.createActivity({ stat: hashed }));
  }

  private processBuffer(): void {
    if (!UserService.$userId() || !this.buffer.length) {
      return;
    }

    while (this.buffer.length) {
      const activity = this.buffer.shift();
      this.dispatch({ ...activity, userId: UserService.$userId() });
    }
  }
}
